import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Image from "../components/image"
import { css } from "@emotion/core"
import { colors, mq } from "../styles/styles"
import SEO from "../components/SEO"
import Hero from "../components/hero"
import Trial from "../components/trial"
import linkResolver from "../utils/linkResolver"
import { isExternalLink, isDocumentLink } from "../utils/utils"
import LocalizedLink from "../components/localizedLink"
import { ArrowScrollToTop } from "../components/arrowScrollToTop"
import '../styles/apipage/imageapi.scss'

const ApiPage = ({ data, pageContext: { locale }, location }) => {
  const ApiPageData = data.prismic.apipage

  return (
    <>
      <SEO
        title={ApiPageData.meta_title}
        desc={ApiPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        location={location}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: ApiPageData.title,
          subtitle: ApiPageData.subtitle,
          image: {
            image: ApiPageData.main_image,
            sharp: ApiPageData.main_imageSharp,
          },
        }}
      />
      <section className="section is-medium" css={apiPageCss}>
        <div className="container">
          <div className="columns is-centered is-vcentered">
            <div className="column is-6">
              {ApiPageData.content_image && (
                <figure className="py-6 px-6">
                  <Image
                    sharp={ApiPageData.content
                      
                    }
                    image={ApiPageData.content_image}
                  />
                </figure>
              )}
            </div>
            <div className="column is-6 column__cta">
              <div className="content">
                <h3 className="title has-text-weight-medium">
                  {RichText.asText(ApiPageData.content_title)}
                </h3>
                <div className="has-text-weight-light">
                  {ApiPageData.content_richtext &&
                    RichText.render(ApiPageData.content_richtext, linkResolver)}
                </div>
              </div>
              {ApiPageData.content_button_link &&
                isExternalLink(ApiPageData.content_button_link) && (
                  <div className="btn_css" id="trial-bt">
                  <a
                    className="button is-rounded button__primary has-text-white"
                    href={ApiPageData.content_button_link.url}
                  >
                    {ApiPageData.content_button}
                  </a>
                  </div>
                )}
              {ApiPageData.content_button_link &&
                isDocumentLink(ApiPageData.content_button_link) && (
                  <LocalizedLink
                    to={ApiPageData.content_button_link._meta.uid}
                    className="button is-primary"
                  >
                    {ApiPageData.content_button}
                  </LocalizedLink>
                )}
            </div>
          </div>
          <div className="columns pt-6">
            {ApiPageData.api_options.map(option => {
              return (
                <div key={option.api_option_id} className="column">
                  <div className="box py-2 px-2 card">
                    {option.api_option_image && (
                      <figure className="image is-64x64">
                        <Image
                          sharp={option.api_option_imageSharp}
                          image={option.api_option_image}
                        />
                      </figure>
                    )}
                    <h4 className="title is-size-5 has-text-left has-text-weight-medium is-uppercase mt-5">
                      {RichText.asText(option.api_option_title)}
                    </h4>
                    {/* {option.api_option_content &&
                      RichText.render(option.api_option_content, linkResolver)}*/}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <Trial trial={data.prismic.allTrialcomponents.edges[0].node} />
      <ArrowScrollToTop />
    </>
  )
}

export default ApiPage

ApiPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const ApiQuery = graphql`
  query ApiQuery($uid: String!, $locale: String!) {
    prismic {
      apipage(uid: $uid, lang: $locale) {
        meta_title
        meta_description
        title
        subtitle
        main_image
        content_title
        content_richtext
        content_button
        content_button_link {
          _linkType
          ... on PRISMIC__ExternalLink {
            url
          }
          ... on PRISMIC__Document {
            _meta {
              uid
              lang
            }
          }
        }
        content_image
        api_options {
          api_option_id
          api_option_title
          api_option_content
          api_option_image
        }
      }

      ...Trial
    }

    site {
      ...SiteInformation
    }
  }
`
const apiPageCss = css`
  .box {
    border-radius: 10px;
    border: 1px solid ${colors.lightgray};
    box-shadow: none;
    height: 100%;
  }
  @media (min-width: 1024px) {
    .column__cta {
      padding: 100px;
    }
  }
  .btn_css{
    display:inline-block;
  }
`
